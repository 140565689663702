<template>
  <div class="section">
    <div class="container">
      <div id="instruction">
        <div class="row">
          <div class="col-lg-12 text-center">
            <img src="../../assets/images/Illustration-instruction.png" alt="">
            <h2>INSTRUKSI</h2>        
          </div>
          <div class="col-lg-12">
            <p class="text-center">Setiap nomor di bawah ini memuat 4 (empat) kalimat.  Tugas anda adalah:</p>
            <ul>
              <li>
                Anda akan dihadapkan 2 pernyataan setiap nomornya. Pilihlah 1 jawaban yang paling menggambarkan diri Anda.
              </li>
              <li>
                Jika Anda merasa diantara 2 pilihan tersebut tidak menggambarkan diri Anda, pilihlah 1 pilihan yang paling mendekati diri Anda.
              </li>
            </ul>
          </div>
          <div class="col-lg-12 text-center">
            <router-link class="btn btn-outline-violet" :to="{name: 'quiz-section'}">
              Mulai tes
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Instruction"
};
</script>

<style scoped>

</style>
